export function isExternal(path) {
    return /^(https?:|mailto:|tel:)/.test(path)
}

export const validateTel = (rule, value, callback) => { //验证用户手机号
    let myreg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
    if (!myreg.test(value)) {
        callback(new Error('错误的手机号'));
    } else {
        callback();
    }
};

export const validateWallet = (rule, value, callback) => {
    if (!value) {
        callback(new Error("请输入钱包地址"));
    } else {
        let pat = new RegExp("^[A-Z a-z 0-9]+$");

        if (pat.test(value)) {
            callback();
        } else {
            callback(new Error("请输入正确的钱包地址"));
        }
    }
};

export const storageConversion = (limit, num = 0) => {
    let size = ''
    limit = limit * 1
    if (limit < 1024) {
        size = limit + 'B'
    } else if (limit < 1024 * 1024) {
        size = (limit / 1024).toFixed(num) + 'kB'
    } else if (limit < 1024 * 1024 * 1024) {
        size = (limit / 1024 / 1024).toFixed(num) + 'M'
    } else if (limit < 1024 * 1024 * 1024 * 1024) {
        size = (limit / 1024 / 1024 / 1024).toFixed(num) + 'G'
    } else {
        size = (limit / 1024 / 1024 / 1024 / 1024).toFixed(num) + 'T'
    }
    return size
}
