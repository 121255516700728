import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import api from '@/api/public/index'
import Bus from "@/assets/js/eventBus";
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import VueClipboard from 'vue-clipboard2'
import '@/icons';
import utilsPublic from "./utils/public";
import utils_index from "./utils/utils_index";
import '@/assets/css/public.css'
import svgIconPlugin from '@/icons/index.js'
import * as echarts from "echarts";
import "@/assets/css/comm.css"
import 'default-passive-events'
import auth from "@/common/permission";
import svgIcon  from "@/components/SvgIcon/index.vue";

Vue.use(svgIconPlugin)
Vue.component('svg-icon',svgIcon)
Vue.config.productionTip = false
Vue.prototype._auth = auth;
Vue.prototype.$api = api;
Vue.prototype.$utilsPublic = utilsPublic;
Vue.prototype.$utils = utils_index;
Vue.prototype.$echarts = echarts;
Vue.prototype.$actionUrl =  process.env.VUE_APP_API_HOST;
Vue.use(Bus);
Vue.use(ElementUI);
Vue.use(VueClipboard);
new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
