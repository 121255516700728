export default {
    // 时间转换 yyyy-MM-dd HH-mm-ss
    transDate(time) {
        return new Promise((resolve, reject) => {
            if (!time) return resolve(undefined);
            let date = new Date(time);
            let yyyy = date.getFullYear() + '-';
            let MM = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
            let dd = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' ';
            let HH = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':';
            let mm = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':';
            let ss = (date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds());
            resolve(yyyy + MM + dd + HH + mm + ss);
        });
    },
    // 时间转换 yyyy-MM-dd HH-mm-ss
    transDateRes(time) {
        if (!time) return undefined;
        let date = new Date(time);
        let yyyy = date.getFullYear() + '-';
        let MM = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
        let dd = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' ';
        let HH = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':';
        let mm = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':';
        let ss = (date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds());
        return yyyy + MM + dd + HH + mm + ss;
    },
    // 文件大小转换
    fileSizeHandle(size, num) {
        num = num || 2;
        const units = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
        let unitIndex = 0;
        while (size >= 1024 && unitIndex < units.length - 1) {
            size /= 1024;
            unitIndex++;
        }

        const convertedSize = size.toFixed(num);

        const convertedString = convertedSize.replace(/\.?0*$/, '');

        return convertedString + ' ' + units[unitIndex];
    },
    //GB转字节
    gigabytesToBytes(gb) {
        const bytesInGigabyte = 1073741824; // 1GB = 1073741824 bytes
        return gb * bytesInGigabyte;
    },
}
