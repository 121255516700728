import {post, get, del, postFormData} from '../axiosconfig'
import qs from 'qs'
export default {

  //获取用户信息
  getUserInfo(params){
    params = qs.stringify(params)
    return get('/zsc/distributor/info', params)
  },
  // 汇款记录
  getTransferlist(params) {
    return post('/zsc/distributor/transfer/list', params)
  },
  // 渠道商 list
  getContainerlist(params){
    params = qs.stringify(params)
    return get('/zsc/distributor/container/list', params)
  },
  // 上传凭证
  sendApplyRemit(params) {
    return postFormData('/zsc/distributor/pay/apply/remit', params)
  },
  // 获取密钥
  getOauthSecret(params) {
    params = qs.stringify(params)
    return get('/zsc/distributor/secret/get', params)
  },


}
