import Vue from "vue";
import VueRouter from 'vue-router'
import { loginAction } from "./modules/loginRouter";
import { distrRouter } from "./modules/distrRouter";

Vue.use(VueRouter)
//防止路由重复跳转
const VueRouterPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(to) {
  return VueRouterPush.call(this, to).catch(err => err)
}
const router = new VueRouter({
  mode: "history",
  routes: [

    // 登录路由
    ...loginAction,

    // 首页路由
    {
      path: "/",
      name: "index",
      component: resolve => require(["@/views/indexPage"], resolve),
      children: [
        ... distrRouter
      ],
    },
    // 404 页面
    {
      path: "*",
      name: "404",
      component: resolve => require(["@/views/notFind404/notFind404"], resolve)
    }
  ]
});
router.beforeEach((to, from, next) => {
  // console.log(to);

  if(to.meta.title) {
    document.title = to.meta.title;
  } else {
    document.title = "渠道商管理平台";
  }

  var token = localStorage.getItem("token");
  if (to.name !== 'loginIndex' && !token) {
    next({name: 'loginIndex'});
  } else if (to.name == 'loginIndex' && token) {
    next({name: 'indexPage'});
  } else {
    next()
  }
});
export default router;

